var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"title":"Editer une information additionnelle","id":"edit-information-additionnelle","cancel-variant":"outline-secondary","ok-title":"Ajouter","cancel-title":"Fermer","centered":"","ok-disabled":invalid,"no-close-on-backdrop":""},on:{"ok":function($event){return _vm.submit()},"hidden":function($event){return _vm.test()},"shown":function($event){return _vm.onModalDisplay()}}},[(!_vm.loading)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"titre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"titre"}},[_vm._v("Titre : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"titre","type":"text","placeholder":"Titre","state":errors.length > 0 ? false : null},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createInformationAdditionnelle.apply(null, arguments)}},model:{value:(_vm.informationAdditionnelle.titre),callback:function ($$v) {_vm.$set(_vm.informationAdditionnelle, "titre", $$v)},expression:"informationAdditionnelle.titre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Fonctions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"titre"}},[_vm._v("Fonctions : ")]),_c('b-form-checkbox-group',{staticClass:"mt-2 mb-2",attrs:{"label":"libelle","options":_vm.listFonctions},model:{value:(_vm.informationAdditionnelle.fonctionsAllowed),callback:function ($$v) {_vm.$set(_vm.informationAdditionnelle, "fonctionsAllowed", $$v)},expression:"informationAdditionnelle.fonctionsAllowed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Veuillez sélectionner au moins une fonction" : ""))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Groupes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"titre"}},[_vm._v("Groupes :")]),_c('b-form-checkbox-group',{staticClass:"mt-2 mb-2",attrs:{"label":"libelle","options":_vm.listGroupes},model:{value:(_vm.informationAdditionnelle.groupesAllowed),callback:function ($$v) {_vm.$set(_vm.informationAdditionnelle, "groupesAllowed", $$v)},expression:"informationAdditionnelle.groupesAllowed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Veuillez sélectionner au moins un groupe" : ""))])]}}],null,true)}),(
            _vm.computedTags.length && 
            _vm.tenantId == _vm.tenants.Sstrn
        )?_c('div',[_c('b-form-group',[_c('label',{attrs:{"for":"tags"}},[_vm._v("Tags")]),_c('vue-select',{attrs:{"label":"libelle","multiple":"","placeholder":"Choix d'un ou plusieurs tags","options":_vm.computedTags,"hasNextPage":false,"loadOnCreate":true,"searchable":false,"reduce":function (x) { return x.id; }},model:{value:(_vm.informationAdditionnelle.tagsId),callback:function ($$v) {_vm.$set(_vm.informationAdditionnelle, "tagsId", $$v)},expression:"informationAdditionnelle.tagsId"}})],1)],1):_vm._e(),_c('validation-provider',{attrs:{"rules":"min:5|max:3500","name":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{attrs:{"for":"message"}},[_vm._v("Message : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-textarea',{attrs:{"id":"message","placeholder":"Message","state":errors.length > 0 ? false : null},model:{value:(_vm.informationAdditionnelle.message),callback:function ($$v) {_vm.$set(_vm.informationAdditionnelle, "message", $$v)},expression:"informationAdditionnelle.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-md-6"},[_c('validation-provider',{attrs:{"name":"icone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"icone"}},[_vm._v("Icône : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('vue-select',{attrs:{"label":"iconeLabel","placeholder":"Choix de l'icône","options":_vm.icones,"hasNextPage":false,"loadOnCreate":true,"searchable":false,"reduce":function (x) { return _vm.getIconeKey(x); }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('li',{ref:"load",staticClass:"loader"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":_vm.getIconeKey(option.iconeLabel) + 'Icon',"size":"17"}}),_vm._v(" "+_vm._s(option.iconeLabel)+" ")],1)]}}],null,true),model:{value:(_vm.informationAdditionnelle.icone),callback:function ($$v) {_vm.$set(_vm.informationAdditionnelle, "icone", $$v)},expression:"informationAdditionnelle.icone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-6 col-md-6"},[_c('validation-provider',{attrs:{"name":"couleur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"couleur"}},[_vm._v("Couleur : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('vue-select',{attrs:{"label":"couleurLabel","placeholder":"Choix de la couleur","options":_vm.couleurs,"hasNextPage":false,"loadOnCreate":true,"searchable":false,"reduce":function (x) { return _vm.getCouleurKey(x); }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('li',{ref:"load",class:'text-' + _vm.getCouleurKey(option.couleurLabel) + ' loader'},[_vm._v(" "+_vm._s(option.couleurLabel)+" ")])]}}],null,true),model:{value:(_vm.informationAdditionnelle.couleur),callback:function ($$v) {_vm.$set(_vm.informationAdditionnelle, "couleur", $$v)},expression:"informationAdditionnelle.couleur"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])],1)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Chargement...","variant":"primary"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }