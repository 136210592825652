import appApiTypes from '@/api'

function IsJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export default {
  namespaced: true,
  state: {
    user: localStorage.getItem('me') && IsJsonString(localStorage.getItem('me')) ? JSON.parse(localStorage.getItem('me')) : null,
    droits: localStorage.getItem('user_ui_rights') && IsJsonString(localStorage.getItem('user_ui_rights')) ? JSON.parse(localStorage.getItem('user_ui_rights')) : [],
    fonctions: localStorage.getItem('user_ui_fonctions') && IsJsonString(localStorage.getItem('user_ui_fonctions')) ? JSON.parse(localStorage.getItem('user_ui_fonctions')) : [],
    fonctions_codes: localStorage.getItem('user_ui_fonctions_codes') && IsJsonString(localStorage.getItem('user_ui_fonctions_codes')) ? JSON.parse(localStorage.getItem('user_ui_fonctions_codes')) : [],
    user_preferences: localStorage.getItem('user_ui_preferences') && IsJsonString(localStorage.getItem('user_ui_preferences')) ? JSON.parse(localStorage.getItem('user_ui_preferences')) : [],
    ai_allowed_tags: localStorage.getItem('user_ai_allowed_tags') && IsJsonString(localStorage.getItem('user_ai_allowed_tags')) ? JSON.parse(localStorage.getItem('user_ai_allowed_tags')) : []
  },
  getters: {
    me: function(state) {
      return { ...state.user, fonctions: state.fonctions, fonctionsCodes: state.fonctions_codes, userPreferences: state.user_preferences }
    },
    droits: state => state.droits,
    fonctions: state => state.fonctions,
    fonctions_codes: state => state.fonctions_codes,
    user_preferences: state => state.user_preferences,
    ai_allowed_tags: state => state.ai_allowed_tags
  },
  mutations: {
    SET_ME(state, user) {
      localStorage.setItem('me', JSON.stringify(user))
      state.user = user
    },
    SET_DROITS(state, droits) {
      localStorage.setItem('user_ui_rights', JSON.stringify(droits))
      state.droits = droits
    },
    SET_FONCTIONS(state, fonctions) {
      localStorage.setItem('user_ui_fonctions', JSON.stringify(fonctions))
      state.fonctions = fonctions
    },
    SET_FONCTIONS_CODES(state, fonctions_codes) {
      localStorage.setItem('user_ui_fonctions_codes', JSON.stringify(fonctions_codes))
      state.fonctions_codes = fonctions_codes
    },
    SET_ALLOWED_AI_TAGS(state, allowed_tags) {
      localStorage.setItem('user_ai_allowed_tags', JSON.stringify(allowed_tags))
      state.allowed_tags = allowed_tags
    },
    UPDATE_ME(state, profile) {
      let user = JSON.parse(localStorage.getItem('me'));
      user.photo = profile.photo;
      user.sasToken = profile.sasToken;
      localStorage.setItem('me', JSON.stringify(user));
      state.user = user;
    },
    SET_PREFERENCES_UTILISATEUR(state, preferences_utilisateur) {
      localStorage.setItem('user_ui_preferences', JSON.stringify(preferences_utilisateur))
      state.user_preferences = preferences_utilisateur
    }
  },
  actions: {
    setMe({ commit }, user) {
      commit('SET_ME', user);
    },
    fetchDroits({ commit }) {
      return new Promise((resolve, reject) => {
        appApiTypes.myressif.myrights.get()
          .then(response => {
            commit('SET_DROITS', response.droits);
            commit('SET_FONCTIONS', response.fonctions)
            commit('SET_FONCTIONS_CODES', response.fonctionsCode)
            commit('SET_ALLOWED_AI_TAGS', response.additionalInformationTags)

            
            const parsedUrl = new URL(window.location);
    
            // Extraire le nom d'hôte (host)
            const hostname = parsedUrl.hostname;
            
            // Séparer le nom d'hôte en segments et récupérer les deux derniers segments (le domaine)
            const domainParts = hostname.split('.');
            const domain = domainParts.slice(-2).join('.'); // Récupère les deux derniers segments
        

            document.cookie.split(';').forEach(cookie => {
              const [name] = cookie.trim().split('=');
              if (name.startsWith('oidc.')) {
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${domain};`;
              }
            });
            
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          })
      })
    },
    updateProfile({ commit }) {
      return new Promise((resolve, reject) => {
        appApiTypes.myressif.myprofile.get()
          .then(response => {
            commit('UPDATE_ME', response);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          })
      })
    },
    saveUserPreference({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        appApiTypes.myressif.preferencesUtilisateur.post(payload)
          .then(response => {
            dispatch('fetchPreferencesUtilisateur')
          })
          .catch(error => {
            console.log(error);
            reject(error)
          })
      })
    },
    updateUserPreference({ commit, dispatch },id, payload) {
      return new Promise((resolve, reject) => {
        appApiTypes.myressif.preferencesUtilisateur.put(id ,payload)
          .then(response => {
            dispatch('fetchPreferencesUtilisateur')
          })
          .catch(error => {
            console.log(error);
            reject(error)
          })
      })
    },
    fetchPreferencesUtilisateur({ commit }, payload) {
      return new Promise((resolve, reject) => {
        appApiTypes.myressif.preferencesUtilisateur.get()
          .then(response => {
            commit('SET_PREFERENCES_UTILISATEUR', response.items)
          })
          .catch(error => {
            console.log(error);
            reject(error)
          })
      })
    }
  }
}