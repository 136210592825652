var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"title":"Ajout d'une information additionnelle","size":"xl","id":"add-new-information","cancel-variant":"outline-secondary","ok-title":"Ajouter","cancel-title":"Fermer","centered":"","ok-disabled":invalid,"no-close-on-backdrop":""},on:{"ok":function($event){return _vm.submit()}}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"titre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"titre"}},[_vm._v("Titre : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"titre","type":"text","placeholder":"Titre","state":errors.length > 0 ? false : null,"F":""},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createInformationAdditionnelle.apply(null, arguments)}},model:{value:(_vm.newInformationAdditionnelle.titre),callback:function ($$v) {_vm.$set(_vm.newInformationAdditionnelle, "titre", $$v)},expression:"newInformationAdditionnelle.titre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Fonctions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row mx-1 mt-2 mb-2"},[_c('label',{attrs:{"for":"titre"}},[_vm._v("Fonctions :")]),_c('b-form-checkbox-group',{staticClass:"text-center",attrs:{"label":"libelle"},model:{value:(_vm.newInformationAdditionnelle.fonctionsAllowed),callback:function ($$v) {_vm.$set(_vm.newInformationAdditionnelle, "fonctionsAllowed", $$v)},expression:"newInformationAdditionnelle.fonctionsAllowed"}},_vm._l((_vm.listFonctions),function(option){return _c('b-form-checkbox',{key:option.value,staticClass:"col-5",attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0] ? "Veuillez sélectionner au moins une fonction" : ""))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Groupes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"titre"}},[_vm._v("Groupes :")]),_c('b-form-checkbox-group',{staticClass:"mt-2 mb-2",attrs:{"label":"libelle","options":_vm.listGroupes},model:{value:(_vm.newInformationAdditionnelle.groupesAllowed),callback:function ($$v) {_vm.$set(_vm.newInformationAdditionnelle, "groupesAllowed", $$v)},expression:"newInformationAdditionnelle.groupesAllowed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Veuillez sélectionner au moins un groupe" : ""))])]}}],null,true)}),_c('div',{staticClass:"col-12 text-right"},[_c('div',{staticClass:"btn-preview"},[_c('a',{staticClass:"text link acces-effectif text-underline",on:{"click":_vm.previewRights}},[_vm._v("Voir accès effectif")])])]),(
            _vm.newInformationAdditionnelle.cibleId &&
            _vm.newInformationAdditionnelle.cibleType == _vm.entitiesOptionsEnum.ENTREPRISE && 
            _vm.allowedTags.length
        )?_c('div',[_c('b-form-group',[_c('label',{attrs:{"for":"tags"}},[_vm._v("Tags")]),_c('vue-select',{attrs:{"label":"libelle","multiple":"","placeholder":"Choix d'un ou plusieurs tags","options":_vm.allowedTags,"hasNextPage":false,"loadOnCreate":true,"searchable":false,"reduce":function (x) { return x.id; }},model:{value:(_vm.newInformationAdditionnelle.tagsId),callback:function ($$v) {_vm.$set(_vm.newInformationAdditionnelle, "tagsId", $$v)},expression:"newInformationAdditionnelle.tagsId"}})],1)],1):_vm._e(),_c('validation-provider',{attrs:{"rules":"min:5|max:3500","name":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{attrs:{"for":"message"}},[_vm._v("Message : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-textarea',{attrs:{"id":"message","placeholder":"Message","state":errors.length > 0 ? false : null},model:{value:(_vm.newInformationAdditionnelle.message),callback:function ($$v) {_vm.$set(_vm.newInformationAdditionnelle, "message", $$v)},expression:"newInformationAdditionnelle.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"fichiers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"fichiers"}},[_vm._v("Fichier(s) :")]),_c('b-form-file',{staticClass:"dropzone dropzone-area",attrs:{"multiple":"","browse-text":"Parcourir","placeholder":"Cliquez ici ou glissez-y votre fichier","drop-placeholder":"Vous pouvez lâcher votre fichier"},model:{value:(_vm.newInformationAdditionnelle.fichiers),callback:function ($$v) {_vm.$set(_vm.newInformationAdditionnelle, "fichiers", $$v)},expression:"newInformationAdditionnelle.fichiers"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-md-6"},[_c('validation-provider',{attrs:{"name":"icone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"icone"}},[_vm._v("Icône : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('vue-select',{attrs:{"label":"iconeLabel","placeholder":"Choix de l'icône","options":_vm.icones,"hasNextPage":false,"loadOnCreate":true,"searchable":false,"reduce":function (x) { return _vm.getIconeKey(x); }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('li',{ref:"load",staticClass:"loader"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":_vm.getIconeKey(option.iconeLabel) + 'Icon',"size":"17"}}),_vm._v(" "+_vm._s(option.iconeLabel)+" ")],1)]}}],null,true),model:{value:(_vm.newInformationAdditionnelle.icone),callback:function ($$v) {_vm.$set(_vm.newInformationAdditionnelle, "icone", $$v)},expression:"newInformationAdditionnelle.icone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-6 col-md-6"},[_c('validation-provider',{attrs:{"name":"couleur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"couleur"}},[_vm._v("Couleur : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('vue-select',{attrs:{"label":"couleurLabel","placeholder":"Choix de la couleur","options":_vm.couleurs,"hasNextPage":false,"loadOnCreate":true,"searchable":false,"reduce":function (x) { return _vm.getCouleurKey(x); }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('li',{ref:"load",class:'text-' +
                        _vm.getCouleurKey(option.couleurLabel) +
                        ' loader'},[_vm._v(" "+_vm._s(option.couleurLabel)+" ")])]}}],null,true),model:{value:(_vm.newInformationAdditionnelle.couleur),callback:function ($$v) {_vm.$set(_vm.newInformationAdditionnelle, "couleur", $$v)},expression:"newInformationAdditionnelle.couleur"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Chargement...","variant":"primary"}})],1),_c('b-modal',{ref:"modal-preview",attrs:{"centered":"","id":"modal-preview-right","title":"Accès effectif","hide-footer":"","cancel-title":"Fermer"}},_vm._l((_vm.previewsAccess),function(item,index){return _c('div',{key:index},[(item[1].length > 0)?_c('div',{staticClass:"preview-list"},[_c('h6',[_vm._v(_vm._s(item[0]))]),(item[1])?_c('div',{staticClass:"preview-lust__user"},_vm._l((item[1]),function(user,index){return _c('ul',{key:index},[(user)?_c('li',[_vm._v(_vm._s(user))]):_vm._e()])}),0):_vm._e()]):_vm._e()])}),0)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }