var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticStyle:{"margin":"15px"}},[_c('b-alert',{attrs:{"show":"","variant":"primary"}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"InfoIcon"}}),(_vm.$hasUserpref('DisplayToolTip', 'true'))?_c('span',{staticClass:"ml-25"},[_vm._v(" Dans cet onglet, vous ne retrouverez que les informations additionnelles qui concernent l'entité (utilisateur, fournisseur, adhérent, partenaire) pour laquelle vous lisez les informations actuellement. ")]):_vm._e()],1)])],1),_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v("Informations additionnelles")]),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(
              (_vm.cibleType == 'Partenaire' && _vm.$can('PARTENAIRES_INFOS_ADD:create')) ||
              (_vm.cibleType == 'Fournisseur' && _vm.$can('FOURNISSEURS_INFOS_ADD:create')) ||
              (_vm.cibleType == 'Utilisateur' && _vm.$can('UTILISATEURS_INFOS_ADD:create')) ||
              (_vm.cibleType == 'Entreprise' && _vm.$can('ENTREPRISES_INFOS_ADD:create')) 
            )?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.add-new-information",modifiers:{"add-new-information":true}}],attrs:{"variant":"primary"}},[_c('span',{staticClass:"text-nowrap"},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Ajouter une information additionnelle ")],1)]):_vm._e()],1)]),(_vm.listInformationsAdditionnelles.items.length == 0)?_c('div',[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"card-body"},[_c('app-timeline',_vm._l((_vm.listInformationsAdditionnelles.items),function(info){return _c('app-timeline-item',{key:info.id,attrs:{"subtitle":info.message,"icon":info.icone + 'Icon',"variant":info.couleur}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('h6',[_c('router-link',{attrs:{"to":{
                      name: 'edit-utilisateurs',
                      params: { id: info.createdBy },
                    }}},[_vm._v(_vm._s(info.creator))]),_vm._v(" : "+_vm._s(info.titre)+" ")],1),_c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(info.cibleType)+" : ")]),_c('span',{staticClass:"cible"},[_vm._v(_vm._s(info.cible))])]),_c('div',{staticClass:"d-flex justify-content-start mb-1"},_vm._l((info.tags),function(tag,index){return _c('span',{key:index,staticClass:"badge badge-pill mr-50",class:'badge-' + tag.couleur},[_vm._v(" "+_vm._s(tag.libelle)+" ")])}),0),_c('p',[_vm._v(_vm._s(_vm._f("truncate")(info.message,200, "...")))])]),_c('div',{staticClass:"col-md-3 d-flex justify-content-end"},[_c('div',{staticClass:"wrapper"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("formatDateWithTime")(info.createdWithTime)))]),_c('br'),_c('b-button',{staticClass:"mt-1",attrs:{"pill":"","variant":"outline-success","size":"sm"},on:{"click":function($event){return _vm.openDetailsModal(info.id)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon","size":"16"}})],1),(
                      info.createdBy === _vm.$store.state.user.user.uid ||
                      (_vm.cibleType == 'Partenaire' && _vm.$can('PARTENAIRES_INFOS_ADD:admin')) ||
                      (_vm.cibleType == 'Fournisseur' && _vm.$can('FOURNISSEURS_INFOS_ADD:admin')) ||
                      (_vm.cibleType == 'Utilisateur' && _vm.$can('UTILISATEURS_INFOS_ADD:admin')) ||
                      (_vm.cibleType == 'Entreprise' && _vm.$can('ENTREPRISES_INFOS_ADD:admin')) 
                    )?_c('b-button',{staticClass:"mt-1 ml-1",attrs:{"pill":"","variant":"outline-secondary","size":"sm"},on:{"click":function($event){return _vm.openEditModal(info.id)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon","size":"16"}})],1):_vm._e(),(
                      info.createdBy === _vm.$store.state.user.user.uid ||
                      (_vm.cibleType == 'Partenaire' && _vm.$can('PARTENAIRES_INFOS_ADD:admin')) ||
                      (_vm.cibleType == 'Fournisseur' && _vm.$can('FOURNISSEURS_INFOS_ADD:admin')) ||
                      (_vm.cibleType == 'Utilisateur' && _vm.$can('UTILISATEURS_INFOS_ADD:admin')) ||
                      (_vm.cibleType == 'Entreprise' && _vm.$can('ENTREPRISES_INFOS_ADD:admin')) 
                    )?_c('b-button',{staticClass:"mt-1 ml-1",attrs:{"pill":"","variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.openDeleteModal(info.id)}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"16"}})],1):_vm._e()],1)])]),_c('b-button-group',_vm._l((info.fichiers),function(fichier){return _c('b-button',{key:fichier.id,staticClass:"mt-1 mr-1",attrs:{"pill":"","variant":"outline-primary","size":"sm","title":"Voir les fichiers"},on:{"click":function($event){return _vm.downloadFile(info.id, fichier.id)}}},[_c('feather-icon',{attrs:{"icon":"FileIcon"}}),_vm._v(" "+_vm._s(fichier.nom)+" ")],1)}),1)],1)}),1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"cols":"12"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.listInformationsAdditionnelles.totalCount,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)])]),_c('create-information-additionnelle',{attrs:{"cibleType":_vm.cibleType,"cibleId":_vm.cibleId},on:{"info-added":_vm.loadInformationsAdditionnelles}}),_c('edit-information-additionnelle',{attrs:{"informationAdditionnelleId":_vm.selectedId},on:{"reload-liste":_vm.loadInformationsAdditionnelles}}),_c('details-information-additionnelle',{attrs:{"informationAdditionnelleId":_vm.selectedId},on:{"reload-liste":_vm.loadInformationsAdditionnelles}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('h5',[_vm._v("Aucune information additionnelle disponible")])])}]

export { render, staticRenderFns }