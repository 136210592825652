var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Détails de l'information additionnelle","id":"details-information-additionnelle","cancel-variant":"outline-secondary","cancel-title":"Fermer","centered":"","size":"lg","no-close-on-backdrop":""},on:{"shown":function($event){return _vm.onModalDisplay()}}},[(!_vm.loading)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"titre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"titre"}},[_vm._v("Titre : ")]),_c('b-form-input',{attrs:{"id":"titre","readonly":"","type":"text","placeholder":"Titre","state":errors.length > 0 ? false : null},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createInformationAdditionnelle.apply(null, arguments)}},model:{value:(_vm.informationAdditionnelle.titre),callback:function ($$v) {_vm.$set(_vm.informationAdditionnelle, "titre", $$v)},expression:"informationAdditionnelle.titre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3781952621)}),_c('validation-provider',{attrs:{"name":"Fonctions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"titre"}},[_vm._v("Fonction(s) : ")]),_c('b-form-checkbox-group',{staticClass:"mt-2 mb-2",attrs:{"label":"libelle","disabled":"","options":_vm.listFonctions},model:{value:(_vm.informationAdditionnelle.fonctionsAllowed),callback:function ($$v) {_vm.$set(_vm.informationAdditionnelle, "fonctionsAllowed", $$v)},expression:"informationAdditionnelle.fonctionsAllowed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Veuillez sélectionner au moins une fonction" : ""))])]}}],null,false,2309284079)}),_c('validation-provider',{attrs:{"name":"Groupes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"titre"}},[_vm._v("Groupes :")]),_c('b-form-checkbox-group',{staticClass:"mt-2 mb-2",attrs:{"label":"libelle","disabled":"","options":_vm.listGroupes},model:{value:(_vm.informationAdditionnelle.groupesAllowed),callback:function ($$v) {_vm.$set(_vm.informationAdditionnelle, "groupesAllowed", $$v)},expression:"informationAdditionnelle.groupesAllowed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Veuillez sélectionner au moins un groupe" : ""))])]}}],null,false,2191268107)}),(
          _vm.informationAdditionnelle.cibleType == _vm.entitiesOptionsEnum.ENTREPRISE && 
          _vm.informationAdditionnelle.tagsId.length
      )?_c('div',[_c('b-form-group',[_c('label',{attrs:{"for":"tags"}},[_vm._v("Tags")]),_c('vue-select',{attrs:{"label":"libelle","value":"id","multiple":"","placeholder":"Choix d'un ou plusieurs tags","options":_vm.computedTags,"hasNextPage":false,"loadOnCreate":true,"searchable":false,"reduce":function (x) { return x.id; }},model:{value:(_vm.informationAdditionnelle.tagsId),callback:function ($$v) {_vm.$set(_vm.informationAdditionnelle, "tagsId", $$v)},expression:"informationAdditionnelle.tagsId"}})],1)],1):_vm._e(),_c('validation-provider',{attrs:{"rules":"min:5|max:400","name":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('label',{attrs:{"for":"message"}},[_vm._v("Message :")]),_c('b-form-textarea',{attrs:{"id":"message","readonly":"","placeholder":"Message","state":errors.length > 0 ? false : null},model:{value:(_vm.informationAdditionnelle.message),callback:function ($$v) {_vm.$set(_vm.informationAdditionnelle, "message", $$v)},expression:"informationAdditionnelle.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,673083951)}),(_vm.informationAdditionnelle.fichiers.length > 0)?_c('label',{staticClass:"mt-1",attrs:{"for":"titre"}},[_vm._v("Fichier(s) : ")]):_vm._e(),(_vm.informationAdditionnelle.fichiers)?_c('br'):_vm._e(),_c('b-button-group',_vm._l((_vm.informationAdditionnelle.fichiers),function(fichier){return _c('b-button',{key:fichier.id,staticClass:"mt-1 mr-1",attrs:{"pill":"","variant":"outline-primary","size":"sm","title":"Voir les fichiers"},on:{"click":function($event){return _vm.downloadFile(_vm.informationAdditionnelle.id, fichier.id)}}},[_c('feather-icon',{attrs:{"icon":"FileIcon"}}),_vm._v(" "+_vm._s(fichier.nom)+" ")],1)}),1),_c('div',{staticClass:"row mt-1"},[_c('div',{staticClass:"col-6 col-md-6"},[_c('validation-provider',{attrs:{"name":"icone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"icone"}},[_vm._v("Icône :")]),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":_vm.iconeLibele + 'Icon',"size":"17"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3589112340)})],1),_c('div',{staticClass:"col-6 col-md-6"},[_c('validation-provider',{attrs:{"name":"couleur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"couleur"}},[_vm._v("Couleur : ")]),_c('vue-select',{attrs:{"label":"couleurLabel","placeholder":"Choix de la couleur","options":_vm.couleurs,"hasNextPage":false,"loadOnCreate":true,"disabled":"","searchable":false,"reduce":function (x) { return _vm.getCouleurKey(x); }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('li',{ref:"load",class:'text-' + _vm.getCouleurKey(option.couleurLabel) + ' loader'},[_vm._v(" "+_vm._s(option.couleurLabel)+" ")])]}}],null,true),model:{value:(_vm.informationAdditionnelle.couleur),callback:function ($$v) {_vm.$set(_vm.informationAdditionnelle, "couleur", $$v)},expression:"informationAdditionnelle.couleur"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2109679101)})],1)])],1)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Chargement...","variant":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }